'use strict';

var $ = require('jquery');
var $trigger = $('.js-smooth-scroll');

var smoothScroll = {

    init: function() {

        $trigger.on('click', function(ev) {

            if (location.pathname.replace(/^\//,'') == this.pathname.replace(/^\//,'') && location.hostname == this.hostname) {
                var target = $(this.hash);
                target = target.length ? target : $('[name=' + this.hash.slice(1) +']');

                if (target.length) {
                    ev.preventDefault();
                    $('html, body').animate({
                        scrollTop: target.offset().top - 20
                    }, 1000);
					$(target).find(':focusable').eq(0).focus();
                }

            }

        });

    }

};

module.exports = smoothScroll;
