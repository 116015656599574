/**
 * Google Tag Manager Events
 */

'use strict';

require('intersection-observer');
var _ = require('lodash');
var $ = require('jquery');

// ensure datalayer is initialized
window.dataLayer = window.dataLayer || [];

var io;
var observedImpressions = [];
var reportedImpressions = [];
var ecomm_pagetype = '';

function getPageType() {
    let i = 0;
    while (!ecomm_pagetype && i < window.dataLayer.length) {
        if (window.dataLayer[i].google_tag_params && window.dataLayer[i].google_tag_params.ecomm_pagetype) {
            ecomm_pagetype = window.dataLayer[i].google_tag_params.ecomm_pagetype;
        }
        i++;
    }
    return ecomm_pagetype;
}

/**
 * Collect as much information regarding the product element (producttile)
 * as possible for impression and click stream data
 */
function getProductElementInfo(element, isProductClickEvent) {
    var $el = $(element);
    var id = $el.attr('data-itemid');
    var name = $el.attr('data-itemname');
    var brand = $el.attr('data-brand');
    var position = null;
    var list = "";
    var category = "";

    // if we have a gridindex on the element or parent container
    // use that as position
    var $posEl = $el.closest('[data-gridindex]');
    if ($posEl.length) {
        position = parseInt($posEl.attr('data-gridindex'), 10) + 1;
    }
    var $listEl = $el.closest('[data-gridname]');
    if ($listEl.length) {
        let gridname = $listEl.attr('data-gridname');
        if (gridname === 'Recommendations') {
            if (ecomm_pagetype === 'product') {
                gridname = 'PDP Recommendations';
            } else if (ecomm_pagetype === 'cart') {
                gridname = 'Cart Recommendations';
            } else if (ecomm_pagetype === 'home') {
                gridname = 'Homepage Recommendations';
            } else if (ecomm_pagetype === 'purchase') {
                gridname = 'Purchase Recommendations';
            } else  if (ecomm_pagetype === 'search' || ecomm_pagetype === 'category' || ecomm_pagetype === 'searchresults') {
                gridname = 'Search Recommendations';
            }
        }
        list = gridname;
    }
    var $catEl = $el.closest('[data-categoryname]');
    if ($catEl.length) {
        category = $catEl.attr('data-categoryname');
    }

    var result = {
        'name' : name,
        'id' : id,
        'list' : list,
        'category' : category,
        'position' : position,
        'brand' : brand
    }

    var isVariant = $el.attr('data-isvariant');
    if (isProductClickEvent) { // Add variant sku in data layer for product click event
        var variant = null;
        if (isVariant) {
            var masterProductID = $el.attr('data-masterproductid');
            if (masterProductID && masterProductID !== 'null') {
                result.id = masterProductID;
                variant = id;
            }
        }
        result.variant = variant;
    }

    return result;
}

/**
 * GTM Data Layer Reporter
 */
function _reporter(target, cb) {
    if (!target && observedImpressions.length === 0) {
        return;
    }

    var dataLayer = window.dataLayer;
    var _dataLayer = {
        'ecommerce' : {}
    };

    _dataLayer.event = "impressions";
    if (target) {
        var _ev = {};
        // target action event override
        _dataLayer.event = "productClick";

        var productClicked = getProductElementInfo(target, true);

        if (productClicked.list) {
            _ev.actionField = {
                'list' : productClicked.list
            }
        }

        _ev.products = [productClicked];

        _dataLayer.ecommerce.click = _ev;
    }

    if (observedImpressions.length > 0) {
        var impressions = _.map(observedImpressions, function(element) {
            return getProductElementInfo(element);
        });

        Array.prototype.push.apply(reportedImpressions, observedImpressions);
        observedImpressions = [];
        _dataLayer.ecommerce.impressions = impressions;
    }

    if (cb) {
        _dataLayer.eventCallback = cb;
    }

    dataLayer.push(_dataLayer);
}
var reporter = _.debounce(_reporter, 3000);


function observer(entries, io) {

    for (var i = 0; i < entries.length; i++){
        var entry = entries[i];
        if (entry.intersectionRatio <= 0)  {
            continue;
        }
        io.unobserve(entry.target);

        if (reportedImpressions.indexOf(entry.target) === -1 &&
            observedImpressions.indexOf(entry.target) === -1) {
            observedImpressions.push(entry.target);
        }
    }

    reporter();
}

/**
 * Call to reinitialize tag manager events such as when
 * new products are loaded on the grid
 */
function reinit() {
    getPageType();
    var i;

    // product impression observers
    var productTiles = document.querySelectorAll('.product-tile');
    for (i=0; i < productTiles.length; i++) {
        var tile = productTiles[i];
        io.observe(tile);
    }

    // product click actions
    $(productTiles).on('click', '', function(ev) {
        var target = $(ev.target);
        if (target.is('img')) {
            var a = target.closest('a');
            if (a.hasClass('thumb-link')) {
                target = a;
            }
        }
        var href = $(target).attr('href');
        if (href) {
            if ($(target).has('.quickview')) {
                _reporter(this);
            } else {
                ev.preventDefault();
                _reporter(this, function() {
                    document.location = href;
                });
            }
        }
    });
}

function init() {
    getPageType();

    // poll for product tile impressions
    io = new IntersectionObserver(observer, {
        threshold: 0.5
    });
    io.POLL_INTERVAL = 1000;

    window.addEventListener('load', function() {
        reinit();
    });

    window.addEventListener('unload', function() {
        // flush immediately
        _reporter();
    });
}


exports.init = init;
exports.reinit = reinit;
