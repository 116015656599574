'use strict';

var account = require('./account'),
    bonusProductsView = require('../bonus-products-view'),
    quickview = require('../quickview'),
    cartStoreInventory = require('../storeinventory/cart');

/**
 * @private
 * @function
 * @description Binds events to the cart page (edit item's details, bonus item's actions, coupon code entry)
 */
function initializeEvents() {
    $('#cart-table').on('click', '.item-edit-details a', function (e) {
        e.preventDefault();
        quickview.show({
            url: e.target.href,
            source: 'cart'
        });
    })
    .on('click', '.bonus-item-actions a, .item-details .bonusproducts a', function (e) {
        e.preventDefault();
        bonusProductsView.show(this.href);
    });

    // override enter key for coupon code entry
    $('form input[name$="_couponCode"]').on('keydown', function (e) {
        if (e.which === 13 && $(this).val().length === 0) { return false; }
    });

    //to prevent multiple submissions of the form when removing a product from the cart
    var removeItemEvent = false;
    $('button[name$="deleteProduct"]').on('click', function (e) {
        if (removeItemEvent) {
            e.preventDefault();
        } else {
            removeItemEvent = true;
        }
    });

}

/**
* @private
* @function
* @description Initialize additional functionality related with PayPal integration
*/
function initPaypalFunctionality() {
    var dialog = require('../dialog');
    var $expressButton = $('.js_paypal_start_ba_checkout');

    $expressButton.on('click', function() {
        if ($expressButton.data('is-address-exist') === true) {
            return true;
        }
        dialog.open({
            url: $expressButton.data('edit-address-url'),
            options: {
                title: $expressButton.data('edit-address-title'),
                open: initEditDefaultShippingAddressForm
            },
        });
        return false;
    });

    function initEditDefaultShippingAddressForm() {
        var $form = $('#paypalEditDefaultShippingAddress');
        $form.on('click', '.apply-button', function() {
            if (!$form.valid()) {
                return false;
            }
            var applyName = $form.find('.apply-button').attr('name');
            var options = {
                url: $form.attr('action'),
                data: $form.serialize() + '&' + applyName + '=x',
                type: 'POST'
            };
            $.ajax(options).done(function(data) {
                if (typeof(data) !== 'string') {
                    if (data.success) {
                        dialog.close();
                        window.location = $expressButton.attr('href');
                    } else {
                        window.alert(data.message);
                        return false;
                    }
                } else {
                    $('#dialog-container').html(data);
                    initEditDefaultShippingAddressForm();
                }
            });
            return false;
        });
        $form.on('click', '.cancel-button, .close-button', function() {
            dialog.close();
            return false;
        });
        $('#paypalSelectSavedAddress').change(function() {
            var data = $(this).val();
            try {
                data = JSON.parse(data);

                for (var addressName in data) {
                    var val = data[addressName];
                    if (typeof val === 'string') {
                        val = val.replace(/\^/g,"'");
                    }
                    $('#dwfrm_profile_address_' + addressName).val(val);
                }
            } catch (e) {
                $form.find('input:text').val('');
                $form.find('select').val('');
            }
        });
    }
}

exports.init = function () {
    initializeEvents();
    if (SitePreferences.STORE_PICKUP) {
        cartStoreInventory.init();
    }
    account.initCartLogin();
    initPaypalFunctionality();
};
