'use strict';

/* globals Modernizr */

var $ = require('jquery'),
    _ = require('lodash'),
    ns = 'js-accordion',
    $accordion = $('.' + ns);

function scrollToSection(targetID) {
    var $target = $(targetID);
    if ($target.length) {
        $target.removeClass('is-expanded');
        $('body').animate({
            scrollTop: $target.offset().top
        },{
            duration: 250,
            done: function() {
                toggleSection(targetID);
            }
        });
    }
}

function toggleSection(target) {
    var $tgt = $(target);
    var $item = $tgt.closest('.' + ns + '__item');
    var $link = $item.find('.' + ns + '__header-link');
    var $body = $item.find('.' + ns + '__body');
    var $icon = $item.find('.' + ns + '__icon');
    var duration = 500;

    $item.addClass('is-expanding');
    $link.attr('aria-expanded', 'false');

    if (!$link.hasClass('disabled')) {
        $link.toggleClass('c-ffl-accordion__link');
        $icon.toggleClass('fa-plus').toggleClass('fa-minus');
    }

    if (Modernizr.csstransitions) {
        var durations = _.map($body.css('transition-duration').split(/,\s?/), function(item) {
            return parseFloat(item.replace(/s$/i, ''));
        });
        duration = 1000 * _.max(durations);
    }
    if ($item.is('.is-expanded')) {
        $item.removeClass('is-expanded');
        setTimeout(function() {
            $item.removeClass('is-expanding');
        }, duration);
    } else {
        setTimeout(function() {
            $item.removeClass('is-expanding').addClass('is-expanded');
            $link.attr('aria-expanded', 'true');
        }, duration);
    }
}

var accordion = {

    init: function() {

        $accordion.on('click', '.' + ns + '__header-link', function(e) {
            e.preventDefault();
            e.stopPropagation();

            toggleSection(e.currentTarget);

        });

        $('body').on('click', '.' + ns + '-scroll-to', function(e) {
            e.preventDefault();
            var $tgt = $(e.currentTarget);
            scrollToSection($tgt.attr('href'));
        });

        $('body').on('click', '.' + ns + '__content-toggle', function(ev) {
            ev.preventDefault();

            var toggleString = $(ev.target).attr('data-toggle');
            var currentString = $(ev.target).text();

            $(ev.target)
                .text(toggleString)
                .attr('data-toggle', currentString)
                .siblings()
                .toggleClass('is-active');
        });

    }

};

module.exports = accordion;
