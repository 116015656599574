'use strict';

var $ = require('jquery');
require('slick-carousel');

var slider = {

    init: function() {

        var $slider = $('.js-slider').not('.slick-initialized');
        var arrows = true;
        var fade = false;

        if ($slider.hasClass('js-slider--secondary')) {
            arrows = false;
            fade = true;
        }

        if ($slider.hasClass('js-slider--header-banner')) {
            arrows = true;
        }

        $.each($slider, function() {
            $(this).slick({
                arrows: arrows,
                autoplay: true,
                autoplaySpeed: 5000,
                fade: fade
            });
            var $active= $(this).children('.slick-list').children('.slick-track').children('.slick-slide slick-current slick-active');
            $active.attr('aria-hidden', 'false');
        });
        $('.pause').on('click', function() {
            $slider
                .slick('slickPause');
        });
        
        $('.play').on('click', function() {
            $slider
                .slick('slickPlay');
        });


    }

};

module.exports = slider;

