'use strict';

var util = require('./util');

module.exports = function () {
    $('#dwfrm_firearmsquestion_interest_2').attr('checked', true);
    $('input[name=dwfrm_firearmsquestion_interest]').on('click', function (e) {
        var $me = $(e.currentTarget);
        if ($me.attr('id') == 'dwfrm_firearmsquestion_interest_2') {
            $('#optionalinfo').hide(); 
            showHideOptional(false); 
        } else {
            $('#optionalinfo').show();
            showHideOptional(true);
        }

        $('#dwfrm_firearmsquestion_interest_0').removeAttr('checked');
        $('#dwfrm_firearmsquestion_interest_1').removeAttr('checked');
        $('#dwfrm_firearmsquestion_interest_2').removeAttr('checked');
         
        $me.prop('checked', 'checked');
    });
    
    $('#sendBtn').on('click', function(e) {

        var form = $(this).closest('form');
        if (!isFormConditionallyValid(form)) {
            return;
        }
        e.preventDefault();
        
        var options = {
            url: util.appendParamToURL(form.attr('action'), 'format', 'ajax'),
            method: 'POST',
            cache: false,
            data: form.serialize()
        };
        $.ajax(options).done(function (response) {
            if (response.Success) {
                $('#tab-heading-3').removeClass('is-expanded');
                form.addClass('visually-hidden');
                $('#thank-you').removeClass('visually-hidden');
                $('html, body').animate({
                    scrollTop: ($('#tab-heading-3').first().offset().top)
                },500);

                window.dataLayer = window.dataLayer || [];
                var dataLayer = window.dataLayer;
                dataLayer.push(
                    {
                        "formName" : "Firearms Question"
                    }
                );
                dataLayer.push({'event': 'formSubmission'});
            } 
        });
    });
};

function isFormConditionallyValid(form) {
    var optVal = -1;
    if ($('#dwfrm_firearmsquestion_interest_0').prop('checked') == true) {
        optVal = 0;
    } else if ($('#dwfrm_firearmsquestion_interest_1').prop('checked') == true) {
        optVal = 1;
    } else if ($('#dwfrm_firearmsquestion_interest_2').prop('checked') == true) {
        optVal = 2;
    }
    
    if (optVal < 0) {
        return false;
    }
    
    if (!form.find('#dwfrm_firearmsquestion_firstname').valid()
            || !form.find('#dwfrm_firearmsquestion_lastname').valid()
            || !form.find('#dwfrm_firearmsquestion_email').valid()
            || !form.find('#dwfrm_firearmsquestion_question').valid()
    ) 
        return false;
    
    if (optVal == 0 || optVal == 1) {
        if (!form.find('#dwfrm_firearmsquestion_street1').valid()
                || !form.find('#dwfrm_firearmsquestion_city').valid()
                || !form.find('#dwfrm_firearmsquestion_state').valid()
                || !form.find('#dwfrm_firearmsquestion_zip').valid()
        ) 
            return false;
    }
    
    return true;
}

function showHideOptional(requireIt) {
    var list = [];
    $('#optionalinfo').find('input').each(function() {
        switch (this.id) {
            case 'dwfrm_firearmsquestion_street1': {
                list.push(this);
                break;
            }
            case 'dwfrm_firearmsquestion_city': {
                list.push(this);
                break;
            }
            case 'dwfrm_firearmsquestion_state': {
                list.push(this);
                break;
            }
            case 'dwfrm_firearmsquestion_zip': {
                list.push(this);
                break;
            }
            default: {
                break;
            } 
        }
    });
    
    if (list.length < 1) {
        return;
    }
    
    list.forEach(function(e) {
        if (requireIt == true) {
            $(e).addClass('required'); 
            $(e).attr('aria-required', true); 
        } else {
            $(e).removeClass('required');
            $(e).attr('aria-required', false);
        }
    });
}
