'use strict';

var $ = require('jquery');
var dialog = require('./dialog');
var trigger = '.open-in-dialog';
var calloutTrigger = '.open-callout-in-dialog';

var contentDialog = {

    init: function() {

        $('body').on('click', trigger, function(ev) {
            ev.preventDefault();

            dialog.open({
                url: $(ev.currentTarget).attr('href'),
                dialogClass: 's-content'
            });
        });

        $('body').on('click', calloutTrigger, function(ev) {
            ev.preventDefault();

            dialog.open({
                html: $(this).find('.js-callout').eq(0).html(),
                dialogClass: 's-content'
            });

        });
    }

};

module.exports = contentDialog;
