'use strict';

var $ = require('jquery');
var util = require('./util');
var $root = $('html');
var $drawer = $('.js-drawer');
var $trigger = $('.js-drawer-trigger');
var $link = $('.js-drawer__link');
var childLevel = '.js-drawer__children';
var activeClass = 'is-active';
var $firstMenuItem=$('.c-global-navigation__link');
var $closeButton = $('.c-icon-menu-close');
var $mobileMenuSection = $('header-search');

var drawer = {

    close: function(ev) {
        if (!ev || (!$drawer.is(ev.target) && $drawer.has(ev.target).length === 0)) {
            ev.preventDefault();
            $root.removeClass(activeClass);
            $drawer.removeClass(activeClass);
        }
        
        $closeButton.on('click touchend', function() {
            ev.preventDefault();
            $root.removeClass(activeClass);
            $drawer.removeClass(activeClass);
            $root.on('click touchend', drawer.close);
        });
    },

    toggleChildren: function(ev) {
        if ($drawer.hasClass(activeClass) && ev.which === 1 || ev.which === 13) {
            ev.preventDefault();
            $(this).toggleClass(activeClass).next(childLevel).toggleClass(activeClass);
        }
    },

    init: function() {

        $trigger.on('click touchend', function(ev) {
            ev.preventDefault();
            ev.stopPropagation();
            $drawer.addClass(activeClass);
            $root.addClass(activeClass);
            $root.on('click touchend', drawer.close);
            $mobileMenuSection.attr('tabindex', -1);
        });

        $trigger.on('keydown', function(ev) {
            if (ev.which==13) {
                ev.preventDefault();
                ev.stopPropagation();
                $trigger.attr('aria-expanded', 'true');
                $drawer.toggleClass(activeClass);
                $root.toggleClass(activeClass);
                $mobileMenuSection.attr('tabindex', -1);
                if ($drawer.hasClass(activeClass)) {
                    setTimeout(function() {
                        $closeButton.focus();
                    }, 500);
                } else {
                    $trigger.attr('aria-expanded', 'false');
                    $trigger.focus();
                }

            }  
            if (ev.which==9) {
                if ($drawer.hasClass(activeClass)) {
                    ev.preventDefault();
                    $firstMenuItem.focus();
                } 
            }
        });

        $link.on('click touchend', drawer.toggleChildren);
        $link.on('keydown', drawer.toggleChildren);


        $('body').on('keydown', function(ev) {
            if (ev.which==27) {
                if ($drawer.hasClass(activeClass)) {
                    ev.preventDefault();
                    $root.removeClass(activeClass);
                    $drawer.removeClass(activeClass);
                    $root.off('click touchend', drawer.close);
                }
            }
        });
        
        $(window).on('resize', function(ev) {

            if (util.matchBreakpoints(['xl-up'])) {
                drawer.close(ev);
            }

        });

        $('.menu-ea__mobile').on(' keypress ontouchend', '.menu-ea__mobile', function(e) {
            if (e.key == 9){
                e.preventDefault();
                $closeButton.focus();
            }
        });

    }

};

module.exports = drawer;
