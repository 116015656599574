'use strict';

var util = require('./util.js');
var $search = $('.js-search');
var trigger = '.js-search-toggle';
var $trigger = $(trigger);
var activeClass = 'is-active';

var search = {

    close: function(ev) {
        if (!ev || $search.hasClass(activeClass)) {
            ev.preventDefault();
            $search.removeClass(activeClass);
            $trigger.off('click touchend', search.close);
        }
    },

    init: function() {
        $trigger.on('click touchend', function(ev) {
            ev.preventDefault();
            $search.addClass(activeClass);
            $trigger.on('click touchend', search.close);
        });

        $(window).on('resize', function(ev) {
            if (util.matchBreakpoints(['xl-up'])) {
                search.close(ev);
            }
        });

    }

};

module.exports = search;
